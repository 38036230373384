import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Franklin's personal website</title>
    <g>
      <g id="B" transform="translate(11.000000, 10.000000)">
        {/* <path
          d="M45.691667,45.15 C48.591667,46.1 50.691667,48.95 50.691667,52.2 C50.691667,57.95 46.691667,61 40.291667,61 L28.541667,61 L28.541667,30.3 L39.291667,30.3 C45.691667,30.3 49.691667,33.15 49.691667,38.65 C49.691667,41.95 47.941667,44.35 45.691667,45.15 Z M33.591667,43.2 L39.241667,43.2 C42.791667,43.2 44.691667,41.85 44.691667,38.95 C44.691667,36.05 42.791667,34.8 39.241667,34.8 L33.591667,34.8 L33.591667,43.2 Z M33.591667,47.5 L33.591667,56.5 L40.191667,56.5 C43.691667,56.5 45.591667,54.75 45.591667,52 C45.591667,49.2 43.691667,47.5 40.191667,47.5 L33.591667,47.5 Z"
          fill="currentColor"
        /> */}

        <path
          d="m28.689 0c-15.819 0-28.688 12.869-28.688 28.688 0 15.818 12.87 28.688 28.689 28.688 15.816 0 28.686-12.869 28.686-28.688s-12.869-28.688-28.687-28.688zm0 54.375c-14.165 0-25.689-11.523-25.689-25.688 0-14.164 11.524-25.688 25.689-25.688 14.163 0 25.686 11.523 25.686 25.688s-11.522 25.688-25.686 25.688z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m36.786 15.863h-14.845c-.553 0-1 .447-1 1v23.107c0 .554.447 1 1 1h3.532c.553 0 1-.446 1-1v-7.786c0-.554.447-1 1-1h8.461c.553 0 1-.447 1-1v-3.107c0-.553-.447-1-1-1h-8.461c-.553 0-1-.447-1-1v-3.105c0-.553.447-1 1-1h9.312c.553 0 1-.447 1-1v-3.107c.001-.554-.446-1.002-.999-1.002z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      {/* <path
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m28.689 0c-15.819 0-28.688 12.869-28.688 28.688 0 15.818 12.87 28.688 28.689 28.688 15.816 0 28.686-12.869 28.686-28.688s-12.869-28.688-28.687-28.688zm0 54.375c-14.165 0-25.689-11.523-25.689-25.688 0-14.164 11.524-25.688 25.689-25.688 14.163 0 25.686 11.523 25.686 25.688s-11.522 25.688-25.686 25.688z"
      /> */}
    </g>
  </svg>
);

export default IconLoader;
