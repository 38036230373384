import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 10 84 16" width='100'>
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <path
          d="m36.786 15.863h-14.845c-.553 0-1 .447-1 1v23.107c0 .554.447 1 1 1h3.532c.553 0 1-.446 1-1v-7.786c0-.554.447-1 1-1h8.461c.553 0 1-.447 1-1v-3.107c0-.553-.447-1-1-1h-8.461c-.553 0-1-.447-1-1v-3.105c0-.553.447-1 1-1h9.312c.553 0 1-.447 1-1v-3.107c.001-.554-.446-1.002-.999-1.002z"
          fill="currentColor"
        />
        <path
          // points="39 0 0 22 0 67 39 90 78 68 78 23"
          fill="currentColor"
          d="m28.689 0c-15.819 0-28.688 12.869-28.688 28.688 0 15.818 12.87 28.688 28.689 28.688 15.816 0 28.686-12.869 28.686-28.688s-12.869-28.688-28.687-28.688zm0 54.375c-14.165 0-25.689-11.523-25.689-25.688 0-14.164 11.524-25.688 25.689-25.688 14.163 0 25.686 11.523 25.686 25.688s-11.522 25.688-25.686 25.688z"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
